.react-grid-layout.layout {
    position: relative;
    transition: height .4s ease;
}

.react-grid-layout.layout .react-grid-item.react-grid-placeholder {
    z-index: 2;
    opacity: 0.5;
    user-select: none;
    border-radius: 28px;
    transition-duration: .4s;
    background: rgba(0, 0, 0, .25);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, .1);
}

.blink-me {
    /*animation: blinker 1s linear infinite;*/
}

.react-grid-item > div.active-item {
    animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
    0% {
        border-color: rgb(0, 21, 41);
    }

    10% {
        border-color: rgba(0, 21, 41, .9);
    }

    20% {
        border-color: rgba(0, 21, 41, .8);
    }

    30% {
        border-color: rgba(0, 21, 41, .7);
    }

    40% {
        border-color: rgba(0, 21, 41, .6);
    }

    50% {
        border-color: rgba(0, 21, 41, .6);
    }

    70% {
        border-color: rgba(0, 21, 41, .7);
    }

    80% {
        border-color: rgba(0, 21, 41, .8);
    }

    90% {
        border-color: rgba(0, 21, 41, .9);
    }

    100% {
        border-color: rgb(0, 21, 41);
    }
}