.closeButton {
    top: 16px;
    right: 16px;
    z-index: 1001;
    display: flex;
    cursor: pointer;
    fill-opacity: .45;
    position: absolute;
    transition: fill-opacity .2s;
}

.closeButton:hover {
    fill-opacity: .65;
}

.customModalWrapper {
    opacity: 0;
    right: 24px;
    bottom: 24px;
    z-index: 1000;
    position: fixed;
    overflow: hidden;
    border-radius: 8px;
    background-color: #fff;
    transform: translateY(20px);
    transition: opacity 0.4s ease, transform 0.3s ease;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.customModalBody {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.formWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.content {
    gap: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 16px 24px 16px 24px;
}

.customModalIsClosed {
    display: none;
}

.customModalIsOpen {
    opacity: 1;
    display: flex;
    transform: translateY(0);
}

.imageWrapper {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}

.imageMask {
    width: 100%;
    height: 100%;
    z-index: 1000;
    position: absolute;
}

@media (max-width: 768px) {
    .customModalWrapper {
        opacity: 1;
        right: unset;
        bottom: unset;
        display: flex;
        transform: unset;
        position: relative;
    }
}