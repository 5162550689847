.templatesContainer {
    gap: 1.6rem;
    width: 100%;
    display: grid;
    overflow: hidden;
    flex-wrap: nowrap;
    grid-auto-flow: column;
}

.templatesContainer > div {
    width: 256px;
    cursor: pointer;
    min-width: 256px;
}

@media (max-width: 768px) {
    .templatesContainer {
        grid-auto-flow: initial;
        justify-content: center;
        grid-template-columns: minmax(calc(33.333% - 16px), 300px) minmax(calc(33.333% - 16px), 300px);
    }

    .templatesContainer > div {
        width: unset;
        min-width: unset;
    }
}