.calculatorsContainer {
    display: flex;
    flex-wrap: nowrap;
    overflow: scroll hidden;
    scroll-behavior: smooth;
}

.calculatorsContainer::-webkit-scrollbar {
    display: none;
}

.calculatorCard {
    display: flex;
    padding: 12px;
    width: max-content;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    border: 1px solid rgba(0, 0, 0, 0.06);
}

.calculatorCard > div {
    flex-grow: 1;
    display: flex;
}

.calculatorCard:hover {
    background: #f9f9f9;
}

.rightOutlinedIcon {
    display: none !important;
}

@media (max-width: 768px) {
    .calculatorCard {
        width: 360px;
    }


    .rightOutlinedIcon {
        display: flex !important;
    }
}