.link {
    color: inherit !important;
    text-decoration: underline !important;
}

.footer {
    display: flex;
    margin-top: 4.8rem;
    align-items: center;
    flex-direction: column;
}

.logoWrapper {
    gap: 10px;
    display: flex;
    padding: 16px;
    background: #FFF;
    border-radius: 8px;
    align-items: center;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08);
}

.privacyPolicy {
    text-align: center !important;
}