.card {
    width: 256px;
    height: 146px;
    display: flex;
    cursor: pointer;
    padding: 0.8rem;
    background: #ffffff;
    align-items: center;
    border-radius: 0.8rem;
    justify-content: center;
    transition: box-shadow 0.3s ease;
    box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.04);
}

.card:hover {
    box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.1);
}

.cardBody {
    gap: 0.8rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 0.4rem;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.04);
    border: 1px dashed rgba(0, 0, 0, 0.15);
}

.typography {
    margin-top: 1px;
    font-size: 16px !important;
    font-weight: 600 !important;
}

@media (max-width: 768px) {
    .card {
        height: 250px;
    }

    .cardBody {
        flex-direction: column;
    }
}